<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-01-24 15:17:15
 * @LastEditors: ------
 * @LastEditTime: 2022-02-25 13:18:53
-->
<template>
  <div class="lookCon">
    <van-nav-bar
      title="查看访谈"
      left-text="返回"
      fixed
      left-arrow
      placeholder
      @click-left="$router.go(-1)"
    />
    <div class="NurseDetail">
      <div class="form">
        <van-field
          v-model="formData.name"
          name="姓名"
          label="姓名"
          required
          placeholder="请填写姓名"
          input-align="right"
        />

        <van-field
          readonly
          clickable
          label="需求服务类型"
          :value="value1 || formData.nurseType_dictText"
          placeholder="选择服务类型"
          @click="showPicker1 = true"
          required
          :rules="[{ required: true }]"
        />
        <van-popup v-model="showPicker1" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns1"
            @cancel="showPicker1 = false"
           
          />
        </van-popup>
      <div class="price" v-if="showPicker9">
          <van-field
            readonly
            clickable
            label="协商价格"
            :value="value8"
            :placeholder="columns8"
            @click="showPicker8 = true"
            required
            :rules="[{ required: true }]"
          />

          <div class="div_price">
            <van-field v-model="formData.negotiatePrice" type="digit" center />
          </div>
        </div>
        <van-field
          v-model="formData.serviceDetailAddress"
          name="服务地址"
          label="服务地址"
          placeholder="请填写服务地址"
          input-align="right"
          required
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="formData.mobile"
          name="客户联系电话"
          label="客户联系电话"
          type="tel"
          maxlength="11"
          required
          placeholder="请填写客户联系电话"
          input-align="right"
          :rules="[{ required: true }]"
        />
      </div>
      <div class="form">
        <van-field
          v-model="formData.serviceRequire"
          autosize
          
          label="服务详情要求"
          type="textarea"
        
        />
        <van-field
          v-model="formData.specialRequire"
          autosize
        
          label="服务人员特殊要求"
          type="textarea"
        
        />
        <van-field
          v-model="formData.notice"
          autosize
          
          label="护理要点和须知"
          type="textarea"
         
        />
        <van-field
          v-model="formData.remark"
          autosize
          label="备注"
          type="textarea"
          placeholder="请输入备注"
        />
      </div>
      <div class="form">
        <van-field
          v-model="formData.age"
          name="年龄"
          label="年龄"
          placeholder="请填写年龄"
          input-align="right"
        />
        <van-field
          v-model="formData.height"
          name="身高"
          label="身高"
          type="number"
          maxlength="3"
          placeholder="厘米"
          input-align="right"
        />
        <van-field
          v-model="formData.weight"
          name="体重"
          label="体重"
          type="number"
          maxlength="3"
          placeholder="公斤"
          input-align="right"
        />
        <van-field
          readonly
          clickable
          label="性别"
          :value="value || formData.sex_dictText"
          placeholder="选择性别"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
          />
        </van-popup>
        <van-field
          v-model="formData.getUpNum"
          name="起夜情况"
          label="起夜情况"
          placeholder="次数"
          input-align="right"
        />

        <van-field
          readonly
          clickable
          label="是否需要做饭"
          :value="value5 || formData.needCook_dictText"
          placeholder="是否需要做饭"
          @click="showPicker5 = true"
        />
        <van-popup v-model="showPicker5" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns5"
            @cancel="showPicker5 = false"
            @confirm="onConfirm5"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          label="服务人员是否住宿"
          :value="value6 || formData.needStay_dictText"
          placeholder="服务人员是否住宿"
          @click="showPicker6 = true"
        />
        <van-popup v-model="showPicker6" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns6"
            @cancel="showPicker6 = false"
            @confirm="onConfirm6"
          />
        </van-popup>
        <van-field
          readonly
          clickable
          label="有无长护险"
          :value="value4 || '无'"
          placeholder="有无长护险"
          @click="showPicker4 = true"
        />
        <van-popup v-model="showPicker4" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns4"
            @cancel="showPicker4 = false"
            @confirm="onConfirm4"
          />
        </van-popup>
        <div
          v-if="
            formData.medicalInsuranceFlag && formData.medicalInsuranceFlag == 10
              ? true
              : false
          "
        >
          <van-field
            readonly
            clickable
            label="长护险等级"
            :value="
              value2 ||
              (formData.medicalInsuranceLevel == 1
                ? '一级'
                : formData.medicalInsuranceLevel == 2
                ? '二级'
                : formData.medicalInsuranceLevel == 3
                ? '三级'
                : formData.medicalInsuranceLevel == 4
                ? '四级'
                : formData.medicalInsuranceLevel == 5
                ? '五级'
                : '六级')
            "
            placeholder="选择长护险等级"
            @click="showPicker2 = true"
          />
          <van-popup v-model="showPicker2" round position="bottom">
            <van-picker
              show-toolbar
              :columns="columns2"
              @cancel="showPicker2 = false"
              @confirm="onConfirm2"
            />
          </van-popup>
        </div>

        <van-field
          v-model="formData.familyNum"
          name="家中人数"
          label="家中人数"
          type="digit"
          placeholder="如：2"
          input-align="right"
        />

        <van-field
          readonly
          clickable
          label="服务人员护理周期"
          :value="value3 || formData.medicalInsuranceCycle_dictText"
          placeholder="选择护理周期"
          @click="showPicker3 = true"
        />
        <van-popup v-model="showPicker3" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns3"
            @cancel="showPicker3 = false"
            @confirm="onConfirm3"
          />
        </van-popup>

        <van-field
          readonly
          clickable
          label="是否接受节假日 另议价格"
          :value="value7 || formData.needStay_dictText"
          placeholder="请选择"
          @click="showPicker7 = true"
        />
        <van-popup v-model="showPicker7" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns7"
            @cancel="showPicker7 = false"
            @confirm="onConfirm7"
          />
        </van-popup>
      </div>

      <div class="btn">
        <van-button round block type="info" @click="submmit"
          >生成意向单</van-button
        >
      </div>
      <div class="title"></div>
    </div>
  </div>
</template>

<script>
import { addInterview } from "@/api";
import { Toast, Dialog } from "vant";
export default {
  name: "",
  data() {
    return {
      formData: {
        name: JSON.parse(this.$route.query.obj).name || "",
        age: JSON.parse(this.$route.query.obj).age || "",
        accountFamilyMemberId: JSON.parse(this.$route.query.obj).id || "",
      },
      message: "",
      value: "",
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: "",
      showPicker: false,
      showPicker1: false,
      showPicker2: false,
      showPicker3: false,
      showPicker4: false,
      showPicker5: false,
      showPicker6: false,
      showPicker7: false,
      showPicker8: false,
       showPicker9: true,
      columns: ["男", "女"],
          columns1: ["24小时居家服务", "日间陪护", "夜间陪护","分时照护","医院陪护","长护险新增","长护险旧客新增"],
     columns2: ["一级", "二级", "三级", "四级", "五级", "六级"],
      columns3: ["做六休一", "半月一休", "无休"],
      columns4: ["有", "无"],
      columns5: ["是", "否"],
      columns6: ["是", "否"],
      columns7: ["是", "否"],
     columns8: "协商价格",
    };
  },
  created() {
    // console.log(JSON.parse(this.$route.query.obj));
    const formInfo = JSON.parse(this.$route.query.obj);
    this.formData = formInfo;
    this.onConfirm1(this.formData.nurseType_dictText);
  },
  methods: {
    submmit() {
      let info = JSON.parse(localStorage.getItem("info"));
      this.formData.salesman = info.id;
      this.formData.salesName = info.realname;
      if (!this.formData.name) {
        Toast("请填写用户姓名!");
        return false;
      }
      if (!this.formData.nurseType) {
        Toast("请填写服务类型!");
        return false;
      }
      if (!this.formData.mobile) {
        Toast("请填写联系电话!");
        return false;
      }
      console.log(this.formData);
      addInterview(this.formData).then((res) => {
        if (res.data.success) {
          Dialog.alert({
            message: res.data.message,
          }).then(() => {
            this.$router.push("/customer");
          });
        } else {
          Dialog.alert({
            message: res.data.message,
          });
        }
      });
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
      if (value == "男") {
        this.formData.sex = 1;
      } else {
        this.formData.sex = 2;
      }
    },
    onConfirm1(value) {
       console.log(value)
      
      this.showPicker9 = true;
      this.showPicker1 = false;
      this.columns8 = "";
      this.value8 = "";
      if (value == "24小时居家服务") {
        this.formData.nurseType = 12;
        this.columns8 = "元/月"
      } if (value == "日间照护") {
        this.formData.nurseType = 18;
        this.columns8="元/月"
      } if (value == "夜间照护") {
        this.formData.nurseType = 19;
  
        this.columns8="元/月"
      } if (value == "分时照护") {
        this.formData.nurseType = 11;
       
        this.columns8="元/小时"
      } if (value == "医院陪护") {
        this.formData.nurseType = 13;
      
        this.columns8="元/天"
      } if (value == "长护险新增") {
        this.formData.nurseType = 20;
        this.showPicker9 = false;
      
      }if (value == "长护险旧客新增") {
        this.formData.nurseType = 21;
     this.showPicker9 = false;
      
      }
      this.onConfirm8();
    },
    onConfirm2(value) {
      this.value2 = value;
      this.showPicker2 = false;
      if (value == "一级") return (this.formData.medicalInsuranceLevel = 1);
      if (value == "二级") return (this.formData.medicalInsuranceLevel = 2);
      if (value == "三级") return (this.formData.medicalInsuranceLevel = 3);
      if (value == "四级") return (this.formData.medicalInsuranceLevel = 4);
      if (value == "五级") return (this.formData.medicalInsuranceLevel = 5);
      if (value == "六级") return (this.formData.medicalInsuranceLevel = 6);
    },
    onConfirm3(value) {
      this.value3 = value;
      this.showPicker3 = false;
      if (value == "做六休一") return (this.formData.medicalInsuranceCycle = 1);
      if (value == "半月一休") return (this.formData.medicalInsuranceCycle = 2);
      if (value == "无休") return (this.formData.medicalInsuranceCycle = 3);
    },
    onConfirm4(value) {
      if (value == "有") {
        this.formData.medicalInsuranceFlag = 10;
      } else {
        this.formData.medicalInsuranceFlag = 11;
      }
      this.value4 = value;
      this.showPicker4 = false;
    },
    onConfirm5(value) {
      if (value == "需要") {
        this.formData.needCook = 10;
      } else {
        this.formData.needCook = 11;
      }
      this.value5 = value;
      this.showPicker5 = false;
    },
    onConfirm6(value) {
      if (value == "是") {
        this.formData.needStay = 10;
      } else {
        this.formData.needStay = 11;
      }
      this.value6 = value;
      this.showPicker6 = false;
    },
    onConfirm7(value) {
      if (value == "是") {
        this.formData.needStay = 10;
      } else {
        this.formData.needStay = 11;
      }
      this.value7 = value;
      this.showPicker7 = false;
    },
   onConfirm8() {
      let value= this.columns8;
      if (value == "元/月") return (this.formData.emType = 1);
      if (value == "元/天") return (this.formData.emType = 2);
      if (value == "元/小时") return (this.formData.emType = 3);
    },
  },
};
</script>

<style scoped lang="less">
.lookCon {
  background: #f0efef;
  height: 100vh;
  .form {
    margin: 10px 0;
  }
  /deep/ .van-cell__title {
    width: 140px;
  }
}
/deep/ .van-nav-bar {
  background: linear-gradient(-90deg, #1ed59c, #22d59d);

  .van-nav-bar__title {
    color: #fff;
  }
}

/deep/ .van-nav-bar__left {
  .van-icon,
  .van-nav-bar__text {
    color: #fff;
  }
}
/deep/ .van-field__control {
  text-align: right;
}
.btn {
  margin: 16px;

  /deep/ .van-button {
    background: linear-gradient(-90deg, #0ecde1, #2bd79f);
  }
}
.price {
  position: relative;
  .div_price {
    position: absolute;
    top: 0;
    right: 100px;
    width: 140px;
    /deep/ .van-cell .van-field__control {
      border: 1px solid #ccc;
    }
  }
}
</style>
